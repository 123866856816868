import React from "react";

import "./styles.scss";

const ChangeEmailDialog = (props) => {

  const {
    isOpen,
    leadData,
    handleChangeEmailDialog
  } = props;

  const handleDialogClose = () => {
    handleChangeEmailDialog();
    // const contactUsDialog = document.querySelector("#change-email-dialog-box");
  
    // if(contactUsDialog) {
    //   contactUsDialog.close();
    // }
  };
  
  return (
    <>
      <dialog id="change-email-dialog-box" open={isOpen}>
        <div className="main-container">
          <button className="dialog-toggle-btn" onClick={handleDialogClose}> ✕ </button>
          <i className="helper-text">
                You seem to be using a different email ID today. Please note the email ID you used to register earlier:<br /><br />
            {leadData?.leadEmail ?? "currently saved email."} <br/><br />
            <span>If you'd like to update your email ID, please send us a note at <a target="_blank" className="contact-us" href="/contact-us/">Contact us</a></span>
          </i>
          <button className="submit-btn" onClick={handleDialogClose}>
            Close
          </button>
        </div>
      </dialog>
    </>
  );
};

export default ChangeEmailDialog;