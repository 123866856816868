import React from "react";
import { Helmet } from "react-helmet";
import OnDemandTest from "../../components/OnDemandTest";

const OnDemandTestPage = (props) => {
  return (
    <>
      <Helmet htmlAttributes={{ lang: "en" }}>
        <title>MLP Screening Test</title>
        <meta 
          data-react-helmet="true" 
          name="robots" 
          content="noindex, nofollow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <link 
          data-react-helmet="true" 
          rel="canonical" 
          href="https://www.kraftshala.com/marketing-launchpad/part-time-digital-marketing-course/"
        />
      </Helmet>
      <OnDemandTest params={props.params}/>
    </>
  );
};

export default OnDemandTestPage;