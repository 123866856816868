import React, { useMemo, useState } from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import MobileStepper from "@mui/material/MobileStepper";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import useMediaQuery from "@mui/material/useMediaQuery";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import "./styles.scss";
import { graduationYears } from "../data";
import { CircularProgress, IconButton } from "@mui/material";
import { CONVEX_API_URL } from "../../../../config/config";
import underline from "../../../images/csm/new-underline.png";

export default function LeadDetailsForm(props) {
  const {
    isOpen,
    leadData,
    handleStartTest,
    closeDetailsForm,
    handleAlertOpen,
    refereshLeadData,
    getLeadData,
    handleDetailsForm,
    callCta
  } = props;

  const matches = useMediaQuery(`(max-width: 800px)`);

  const [formFields, setFormFields] = useState({
    birthDate: undefined,
    pincode: "",
    gradYear: "",
    marks: "",
    workExp: null,
    yearsExp: "",
  });

  const [activeStep, setActiveStep] = useState(1);
  const [showError, setShowError] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const isPincodeValid = useMemo(() => {
    // let pincodeRegex = /^\d{6}$/;
    let pincodeRegex = /^[1-9][0-9]{5}$/;

    return pincodeRegex.test(formFields.pincode);
  }, [formFields.pincode]);

  const isMarksValid = useMemo(() => {

    return (formFields.marks > 0 && formFields.marks <= 100) ? true : false;
  }, [formFields.marks]);

  const isYearsExpValid = useMemo(() => {

    return formFields.yearsExp > 0 && formFields.yearsExp <= 50;
  }, [formFields.yearsExp]);

  const handleFieldChange = (event, type = null) => {
    let field = type ?? event?.target?.name;
    let value = type ? event : event?.target?.value;

    if (field) {
      setFormFields({
        ...formFields,
        [field]: value,
      });
    }
  };

  const formValidation = (step,type) => {
    if (
      !formFields.pincode ||
        !isPincodeValid ||
        !formFields.marks ||
        !isMarksValid ||
        !formFields.birthDate ||
        !formFields.birthDate?.isValid() ||
        !formFields.gradYear || 
        (formFields.workExp === "Yes" && (!formFields.workExp || !isYearsExpValid))
    ) {
      setShowError(true);
      callCta(type);
  
      return;
    }
  
    handleActiveStep(step,type);
  };
  const formValidationMob = (step,type) => {
    if (
      !formFields.pincode ||
        !formFields.marks || 
        (formFields.workExp === "Yes" && (!formFields.workExp))
    ) {
      setShowError(true);
      callCta(type);
  
      return;
    }
  
    handleActiveStep(step,type);
  };

  const handleActiveStep = (newStep,type) => {
    
    if (newStep === 1) {
      setActiveStep(newStep);
      callCta(type);

      return;
    }

    if (
      !formFields.birthDate ||
      !formFields.gradYear ||
      !formFields.workExp
    ) {
      setShowError(true);
      callCta(type);

      return;
    }


    setShowError(false);
    setActiveStep(newStep);
    callCta(type);
  };

  const handlePracticetest = (type) => {
    handleFormSubmit('practice',type);
    setTimeout(() => {
      window.open(
        `https://kraftshala.formaloo.me/n5z1fw?email=${leadData?.leadEmail}&number=${leadData?.leadPhoneNo}`,
        "_blank"
      );
    }, 100);
  };

  const handleFormSubmit = async (testType,type) => {
    try {
      // if (
      //   !formFields.birthDate ||
      //   !formFields.birthDate?.isValid() ||
      //   !isPincodeValid ||
      //   !formFields.gradYear ||
      //   !isMarksValid ||
      //   !formFields.workExp
      // ) {
      //   setShowError(true);
      //   callCta(type);

      //   return;
      // }

      // if (formFields.workExp === "Yes" && !isYearsExpValid) {
      //   setShowError(true);
      //   callCta(type);

      //   return;
      // }

      setLoading(true);
      const leadDetailsData = {
        lead_id: leadData?.leadID,
        dob: formFields.birthDate?.toISOString(),
        pin_code: formFields.pincode.toString(),
        passing_year: formFields.gradYear,
        work_experience:
          formFields.workExp === "Yes" ? Math.round(Number(formFields.yearsExp)) : null,
      };

      if (
        !formFields?.gradYear ||
        formFields.gradYear === "Before 2010" ||
        Number(formFields.gradYear) < 2025
      )
        leadDetailsData.marks_in_grad = Math.round(Number(formFields.marks));
      else leadDetailsData.marks_in_12 = Math.round(Number(formFields.marks));

      const url = `${CONVEX_API_URL}/lead/${leadData?.leadID}/details`;
      const result = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(leadDetailsData),
      });
      const data = await result.json();

      if (!result.ok && !result.status === 400) {
        throw data;
      }

      if (data?.id || data.message === "Lead Details Already exist.") {
        setLoading(false);
        getLeadData();
        refereshLeadData();

        if(testType !== 'practice') handleStartTest();
        handleClose();
      }

      // handleClose();
      callCta(type);

    } catch (error) {
      console.error(error);
      setLoading(false);
      handleAlertOpen("!Oops, something went wrong. Please try again later");
      handleClose();
    }
  };

  const handleClose = () => {
    closeDetailsForm();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      maxWidth="lg"
      fullWidth
      scroll="body"
      className="lead-details-form-container"
      sx={{
        "& .MuiDialog-container": {
          backdropFilter: "blur(10px)",
        },
        "& .MuiPaper-root": {
          width: "100%",
          m: 0.5,
          background: "#fff",
          //borderRadius: "40px",
          ...(matches && {
            maxWidth: "calc(100% - 8px) !important",
          }),
        },
      }}
    >
      <DialogContent className="lead-details-from-content">
        {isLoading && (
          <div className="loader-container-main">
            <CircularProgress />
          </div>
        )}
        <button className="dialog-toggle-btn" onClick={handleClose}>
          {" "}
          ✕{" "}
        </button>
        <div className="lead-details-form-hero">
          {/* <img
            src={MLPLogo}
            alt='Marketing Launchpad Logo'
            loading='lazy'
            className='mlp-logo'
          />
          <h1 className="form-header">
                        Kraftshala Screening Test
          </h1>
          <h3 className='form-sub-header'>
            {leadData?.leadName}, we're so glad you're here!
          </h3> */}
          <h1 className="form-header pc-hide">
            <div className="form-header-lead"> {leadData?.leadName}, </div>
            <div className="form-header-best-luck"></div>&nbsp; Best of Luck for{" "}
            <br className="pc-hide" />
            Kraftshala Screening Test
          </h1>
          <h1 className="form-header mobile-hide">
            <div className="form-header-lead">
              {" "}
              {leadData?.leadName}, Best of Luck for Kraftshala Screening Test
            </div>
            <img src={underline} className="form-header-lead-underline" />
          </h1>

          <MobileStepper
            variant="progress"
            steps={3}
            className="mobile-hide"
            position="static"
            activeStep={activeStep}
            sx={{
              maxWidth: 650,
              flexGrow: 1,
              backgroundColor: "#fff !important",
              "& .MuiLinearProgress-root": {
                backgroundColor: "#D9D9D9",
                width: "100%",
              },
              "& .MuiLinearProgress-bar": {
                backgroundColor: "#3DBD69",
              },
            }}
          />
        </div>

        {!matches ? (
          <DesktopForm
            formFields={formFields}
            showError={showError}
            isPincodeValid={isPincodeValid}
            isMarksValid={isMarksValid}
            isYearsExpValid={isYearsExpValid}
            isLoading={isLoading}
            handleFieldChange={handleFieldChange}
            handleFormSubmit={handleFormSubmit}
            handleDetailsForm={handleDetailsForm}
            activeStep={activeStep}
            handleActiveStep={handleActiveStep}
            leadData={leadData}
            handlePracticetest={handlePracticetest}
            formValidation={formValidation}
          />
        ) : (
          <MobileForm
            formFields={formFields}
            activeStep={activeStep}
            showError={showError}
            isPincodeValid={isPincodeValid}
            isMarksValid={isMarksValid}
            isYearsExpValid={isYearsExpValid}
            isLoading={isLoading}
            handleFieldChange={handleFieldChange}
            handleActiveStep={handleActiveStep}
            handleFormSubmit={handleFormSubmit}
            leadData={leadData}
            handlePracticetest={handlePracticetest}
            handleStartTest={handleStartTest}
            formValidation={formValidationMob}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}

const DesktopForm = (props) => {
  const {
    formFields,
    showError,
    isLoading,
    isPincodeValid,
    isMarksValid,
    isYearsExpValid,
    handleFieldChange,
    handleFormSubmit,
    handleActiveStep,
    activeStep,
    handleDetailsForm,
    handlePracticetest,
    formValidation
  } = props;

  const marksLabel = () => {
    if (
      !formFields?.gradYear ||
      formFields.gradYear === "Before 2010" ||
      Number(formFields.gradYear) < 2025
    )
      return "Marks in graduation*";

    return "Marks in Class 12th*";
  };

  return (
    <div className="desktop-main-container">
      {activeStep === 1 && (
        <div className="desktop-form-container">
          <div className="from-fields">
            <label htmlFor="Birth Date">Birth Date*</label>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                disableHighlightToday
                format="DD/MM/YY"
                sx={{
                  // borderRadius: 0,
                  // ". &MuiOutlinedInput-notchedOutline": {
                  //     borderColor: "black !important"
                  // },
                  "& .MuiInputBase-input": {
                    padding: 1,
                  },
                }}
                slotProps={{
                  textField: {
                    error: showError && !formFields?.birthDate?.isValid(),
                    helperText:
                      showError && !formFields?.birthDate?.isValid()
                        ? "Enter a valid birth date"
                        : "",
                  },
                  popper: {
                    sx: {
                      "& .MuiPickersDay-root.Mui-selected": {
                        background: "#F1AE13 !important",
                        ":hover": {
                          background: "#F1AE13",
                        },
                      },
                      "& .MuiPickersDay-root:focus": {
                        background: "#fff",
                      },
                      "& .MuiPickersYear-yearButton.Mui-selected": {
                        background: "#F1AE13",
                      },
                    },
                  },
                }}
                name="birthDate"
                value={formFields.birthDate}
                onChange={(event) => handleFieldChange(event, "birthDate")}
              />
            </LocalizationProvider>
          </div>
          <div className="from-fields">
            <label htmlFor="Pincode">Pincode*</label>
            <TextField
              type="number"
              sx={{
                "& .MuiInputBase-input": {
                  padding: 1,
                },
              }}
              error={showError && !isPincodeValid}
              helperText={
                showError && !isPincodeValid
                  ? "Enter a valid 6 digit pincode"
                  : ""
              }
              placeholder="Enter Pincode"
              name="pincode"
              value={formFields.pincode}
              onChange={handleFieldChange}
            />
          </div>

          <div className="from-fields">
            <label htmlFor="Graduation Year">Graduation Year*</label>
            <Select
              sx={{
                "& .MuiInputBase-input": {
                  padding: 1,
                },
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    maxHeight: "200px",
                  },
                },
              }}
              placeholder="Passing out year"
              error={showError && !formFields.gradYear}
              name="gradYear"
              value={formFields.gradYear}
              onChange={(e) => handleFieldChange(e)}
            >
              {graduationYears.map((data) => {
                return (
                  <MenuItem key={data.value} value={data.value}>
                    {data.label}
                  </MenuItem>
                );
              })}
            </Select>
            {showError && !formFields.gradYear && (
              <p className="error-txt-msg">
                Select an option from the dropdown
              </p>
            )}
          </div>
          <div className="from-fields">
            <label htmlFor="Marks in graduation">{marksLabel()}</label>
            <TextField
              type="number"
              sx={{
                "& .MuiInputBase-input": {
                  padding: 1,
                },
              }}
              placeholder="Enter percentage"
              error={showError && !isMarksValid}
              helperText={
                showError && !isMarksValid
                  ? "Enter percentage between 0-100"
                  : ""
              }
              name="marks"
              value={formFields.marks}
              onChange={handleFieldChange}
            />
          </div>
          <div className="from-fields">
            <label htmlFor="Do you have work-exp">
              Do you have work-experience*
            </label>
            <RadioGroup
              row
              aria-labelledby="work-ex-buttons-group-label"
              name="workExp"
              value={formFields.workExp}
              onChange={(e) => handleFieldChange(e)}
            >
              <FormControlLabel
                value={"Yes"}
                control={
                  <Radio
                    size="small"
                    sx={{
                      "&.Mui-checked": {
                        color: "#F1AE13",
                      },
                    }}
                  />
                }
                label="Yes"
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: "0.875rem",
                  },
                }}
              />
              <FormControlLabel
                value={"No"}
                control={
                  <Radio
                    size="small"
                    sx={{
                      "&.Mui-checked": {
                        color: "#F1AE13",
                      },
                    }}
                  />
                }
                label="No"
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: "0.875rem",
                  },
                }}
              />
            </RadioGroup>
            {showError && !formFields.workExp && (
              <p className="error-txt-msg">Required Field</p>
            )}
          </div>
          {formFields.workExp === "Yes" && (
            <div className="from-fields">
              <label htmlFor="Years of work-ex">Years of work-ex</label>
              <TextField
                type="number"
                sx={{
                  "& .MuiInputBase-input": {
                    padding: 1,
                  },
                }}
                placeholder="Enter years of work-experience"
                error={showError && !isYearsExpValid}
                helperText={
                  showError && !isYearsExpValid
                    ? "Enter experience between 1-50 years"
                    : ""
                }
                name="yearsExp"
                value={formFields.yearsExp}
                onChange={handleFieldChange}
              />
            </div>
          )}
        </div>
      )}
      {activeStep === 2 && (
        <div className="step3Text">
          It is a remotely invigilated test <br />
          <br />
          Click on “Allow” to give camera
          <br /> and microphone access next
        </div>
      )}
      <div className="steperLastStepbtns">
        {activeStep === 1 && (
          <button
            disabled={isLoading}
            className="next-step-btn-pc"
            onClick={() => formValidation(2,"PopUp_Step1_Start_desk")}
          >
            Start Test
          </button>
        )}
        {activeStep === 2 && (
          <div className="steperLastStepbtns">
            <button className="next-step-btn-pc" onClick={() =>handleFormSubmit('',"PopUp_Step2_Start_desk")}>
              Start Test
            </button>
            <button
              className="next-step-btn-a"
              onClick={() =>handlePracticetest("PopUp_Step2_Practice_desk")}
            >
              Warm up with Practice test 
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

const MobileForm = (props) => {
  const {
    formFields,
    activeStep,
    showError,
    isLoading,
    isPincodeValid,
    isMarksValid,
    isYearsExpValid,
    handleFieldChange,
    handleActiveStep,
    handleFormSubmit,
    leadData,
    handlePracticetest,
    formValidation
  } = props;

  const marksLabel = () => {
    if (
      !formFields?.gradYear ||
      formFields.gradYear === "Before 2010" ||
      Number(formFields.gradYear) < 2025
    )
      return "Marks in graduation*";

    return "Marks in Class 12th*";
  };

  return (
    <div className="mob-form-container">
      <div className="progress-container">
        <MobileStepper
          variant="progress"
          steps={4}
          position="static"
          activeStep={activeStep}
          sx={{
            maxWidth: 400,
            flexGrow: 1,
            backgroundColor: "#fff !important",
            "& .MuiLinearProgress-root": {
              backgroundColor: "#D9D9D9",
              width: "100%",
            },
            "& .MuiLinearProgress-bar": {
              backgroundColor: "#3DBD69",
            },
          }}
        />
      </div>
      <div className="form-header">
        {activeStep === 2 && (
          <IconButton
            className="prev-step-btn"
            onClick={() => handleActiveStep(1)}
          >
            <ArrowBackIosIcon fontSize="small" />
          </IconButton>
        )}
        <h3>
          {activeStep === 1 && "Let's start by knowing you:"}
          {activeStep === 2 && "Just a few more details:"}
          {activeStep === 3 && (
            <div className="step3Text">
              It is a remotely invigilated test <br />
              <br />
              Click on “Allow” to give camera
              <br /> and microphone access next
            </div>
          )}
        </h3>
      </div>
      <div className="mob-form-main">
        <>
          <div
            className={`from-fields ${
              activeStep === 2 || activeStep === 3 ? "inactive-field" : ""
            }`}
          >
            <label htmlFor="Birth Date">Birth Date*</label>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                disableHighlightToday
                format="DD/MM/YY"
                sx={{
                  "& .MuiInputBase-input": {
                    padding: 1,
                    fontSize: "0.875rem !important",
                  },
                }}
                slotProps={{
                  textField: {
                    error: showError && !formFields?.birthDate?.isValid(),
                    helperText:
                      showError && !formFields?.birthDate?.isValid()
                        ? "Enter a valid birth date"
                        : "",
                  },
                  popper: {
                    sx: {
                      "& .MuiPickersDay-root.Mui-selected": {
                        background: "#F1AE13 !important",
                        ":hover": {
                          background: "#F1AE13",
                        },
                      },
                      "& .MuiPickersDay-root:focus": {
                        background: "#fff",
                      },
                      "& .MuiPickersYear-yearButton.Mui-selected": {
                        background: "#F1AE13",
                      },
                    },
                  },
                }}
                name="birthDate"
                value={formFields.birthDate}
                onChange={(event) => handleFieldChange(event, "birthDate")}
              />
            </LocalizationProvider>
          </div>
          <div
            className={`from-fields ${
              activeStep === 2 || activeStep === 3 ? "inactive-field" : ""
            }`}
          >
            <label htmlFor="Graduation Year">Graduation Year*</label>
            <Select
              sx={{
                "& .MuiSelect-select": {
                  fontSize: "0.875rem !important",
                },
                "& .MuiInputBase-input": {
                  padding: 1,
                },
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    maxHeight: "125px",
                    "& .MuiMenuItem-root": {
                      fontSize: "0.875rem",
                      minHeight: "30px",
                    },
                  },
                },
              }}
              placeholder="Passing out year"
              error={showError && !formFields.gradYear}
              name="gradYear"
              value={formFields.gradYear}
              onChange={(e) => handleFieldChange(e)}
            >
              {graduationYears.map((data) => {
                return (
                  <MenuItem key={data.value} value={data.value}>
                    {data.label}
                  </MenuItem>
                );
              })}
            </Select>
            {showError && !formFields.gradYear && (
              <p className="error-txt-msg">
                Select an option from the dropdown
              </p>
            )}
          </div>
          <div
            className={`from-fields ${
              activeStep === 2 || activeStep === 3 ? "inactive-field" : ""
            }`}
          >
            <label htmlFor="Do you have work-exp">
              Do you have work-experience*
            </label>
            <RadioGroup
              row
              aria-labelledby="work-ex-buttons-group-label"
              name="workExp"
              value={formFields.workExp}
              onChange={(e) => handleFieldChange(e)}
            >
              <FormControlLabel
                value={"Yes"}
                control={
                  <Radio
                    sx={{
                      "&.Mui-checked": {
                        color: "#F1AE13",
                      },
                      "& .MuiSvgIcon-root": {
                        fontSize: "0.875rem",
                      },
                    }}
                  />
                }
                label="Yes"
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: "0.75rem",
                  },
                }}
              />
              <FormControlLabel
                value={"No"}
                control={
                  <Radio
                    sx={{
                      "&.Mui-checked": {
                        color: "#F1AE13",
                      },
                      "& .MuiSvgIcon-root": {
                        fontSize: "0.875rem",
                      },
                    }}
                  />
                }
                label="No"
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: "0.75rem",
                  },
                }}
              />
            </RadioGroup>
            {showError && !formFields.workExp && (
              <p className="error-txt-msg">Required Field</p>
            )}
          </div>
        </>
        <>
          <div
            className={`from-fields ${
              activeStep === 1 || activeStep === 3 ? "inactive-field" : ""
            }`}
          >
            <label htmlFor="Marks in graduation">{marksLabel()}</label>
            <TextField
              type="number"
              sx={{
                "& .MuiInputBase-input": {
                  padding: 1,
                  fontSize: "0.875rem !important",
                },
              }}
              placeholder="Enter percentage"
              error={showError && !isMarksValid}
              helperText={
                showError && !isMarksValid
                  ? "Enter percentage between 0-100"
                  : ""
              }
              name="marks"
              value={formFields.marks}
              onChange={handleFieldChange}
            />
          </div>
          {formFields.workExp === "Yes" && (
            <div
              className={`from-fields ${
                activeStep === 1 || activeStep === 3 ? "inactive-field" : ""
              }`}
            >
              <label htmlFor="Years of work-ex">
                Years of work-experience*
              </label>
              <TextField
                type="number"
                sx={{
                  "& .MuiInputBase-input": {
                    padding: 1,
                    fontSize: "0.875rem !important",
                  },
                }}
                placeholder="Enter years of work-experience"
                error={showError && !isYearsExpValid}
                helperText={
                  showError && !isYearsExpValid
                    ? "Enter experience between 1-50 years"
                    : ""
                }
                name="yearsExp"
                value={formFields.yearsExp}
                onChange={handleFieldChange}
              />
            </div>
          )}
          <div
            className={`from-fields ${
              activeStep === 1 || activeStep === 3 ? "inactive-field" : ""
            }`}
          >
            <label htmlFor="Pincode">Pincode*</label>
            <TextField
              type="number"
              sx={{
                "& .MuiInputBase-input": {
                  padding: 1,
                  fontSize: "0.875rem !important",
                },
              }}
              error={showError && !isPincodeValid}
              helperText={
                showError && !isPincodeValid
                  ? "Enter a valid 6 digit pincode"
                  : ""
              }
              placeholder="Enter Pincode"
              name="pincode"
              value={formFields.pincode}
              onChange={handleFieldChange}
            />
          </div>
        </>
      </div>
      <div className="submit-btn-container">
        {activeStep === 1 && (
          <button className="next-step-btn" onClick={() => handleActiveStep(2,"PopUp_Step1_Next_mob")}>
            {"Next >"}
          </button>
        )}
        {activeStep === 2 && (
          <button className="next-step-btn" onClick={() => formValidation(3,"PopUp_Step2_Next_mob")}>
            {"Next >"}
          </button>
        )}
        {activeStep === 3 && (
          <div className="steperLastStepbtns">
            <button className="next-step-btn" onClick={()=>handleFormSubmit('',"PopUp_Step3_Start_mob")}>
              Start Test
            </button>
            <button
              className="next-step-btn-a"
              onClick={()=>handlePracticetest("PopUp_Step3_Practice_mob")}
            >
              Warm up with Practice test
            </button>
          </div>
        )}
      </div>
    </div>
  );
};