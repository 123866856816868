import React from "react";

import card11Icon from "../../../images/onDemandTest/whyImg.png";
import card13Icon from "../../../images/onDemandTest/durationImg.png";
import card12Icon from "../../../images/onDemandTest/typeImg.png";
import grayUnderlineImg from "../../../images/onDemandTest/ondemand-underline.png";
import SpeakToCounsellorDialog from "../SpeakToCounsellorDialog";
import CompanyImg from "../../../images/onDemandTest/pcbtmComapny.png";

import "./styles.scss";

const AboutScreeningTest = (props) => {
  const {
    leadEmail,
    leadPhoneNo,
    leadData,
    formType,
    handleDetailsForm
  } = props;

  const handleSpeakToCounsellorDialog = () => {
    const contactUsDialog = document.querySelector("#speak-to-counselor-dialog-box");
    
    if(contactUsDialog) {
      contactUsDialog.showModal();
    }
  };

  return (
    <>
      <section id="about-screening-test" className="about-screening-test-main">
       
        <div className="about-test-cards-container">
          <div className="ondemand-about-header">
            About the Screening Test
          </div>
          <div className="about-test-card-main">
            <div className="card-list-items">
              <div className="card-list-item">
                <img src={card11Icon} alt="check mark icon" className="list-item-icon" />
                <div className="list-item-text">
                  <b>Why</b> - To check your<br className="pc-hide"/> fitment <br className="mobile-hide"/> with the program
                </div>
              </div>
              <div className="card-list-item">
                <img src={card13Icon} alt="scholarship icon" className="list-item-icon" />
                <div className="list-item-text">
                  <b>Duration</b> - 60 mins
                </div>
              </div>
              
              <div className="card-list-item">
                <img src={card12Icon} alt="graduate cap icon" className="list-item-icon" />
                <div className="list-item-text">
                  <b>Type</b> - Subjective <br className="mobile-hide" />(No <br className="pc-hide" /> negative marks)
                </div>
              </div>
              <button className="card-test-link pc-hide" onClick={()=> handleDetailsForm('PracticeTest_inline_mob')}>Give Practice Test</button>
            </div>
          </div>
          
        </div>
        
      </section>
      <div className="on-deman-test-sections-container">
        <div className="ondemand-flex">
          <section className="trusted-by-section">
            <div className="trusted-header">
          Screening Test checks for your ability to learn and apply concepts
            </div>
            <div className="trusted-desc">
          Built from inputs from top marketers
            </div>
          </section>
          <img src={CompanyImg} className="comapny-img-ondemand mobile-hide" />
        </div>
        <section className="program-stats-section">
          <div className="program-stats-data-sec">
            <div className="data">
              <div className="value">150,000+</div>
              <img
                src={grayUnderlineImg}
                alt="underline"
                className="gray-underline"
              />
                        Marketing Aspirants <br/>have taken the test
            </div>
            <div className="data">
              <div className="value">
              320+ 
              </div>
              <img
                src={grayUnderlineImg}
                alt="underline"
                className="gray-underline"
              />
            Companies hired digital
            & content marketing
            folks from Kraftshala
            </div>
            <div className="data">
              <div className="value">
            94% Placement
              </div>
              <img
                src={grayUnderlineImg}
                alt="underline"
                className="gray-underline"
              />
            for all folks who cleared
              <br /> the Screening Test and  <br className="mobile-hide"/>
            enrolled in Kraftshala.     
              <a href="https://placement-reports.kraftshala.com/" target="_blank" className="placement-ondemand">See all placement reports </a>
            </div>
          </div>
        </section>
      </div>
      <SpeakToCounsellorDialog leadData={leadData}/>

    </>
  );
};

export default AboutScreeningTest;
