import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";

import "./styles.scss";

const ScreeningTestPageFooter = (props) => {
  const { hours, minutes, leadData, handleDetailsForm } = props;

  const matches = useMediaQuery(`(max-width: 1024px)`);

  if (matches) {
    return (
      <MobileFooter
        leadData={leadData}
        hours={hours}
        minutes={minutes}
        handleDetailsForm={handleDetailsForm}
      />
    );
  }

  return (
    <DesktopFooter
      leadData={leadData}
      hours={hours}
      minutes={minutes}
      handleDetailsForm={handleDetailsForm}
    />
  );
};

const DesktopFooter = (props) => {
  const { hours, minutes, leadData, handleDetailsForm } = props;

  const InnerHeaderText = () => {
    let scenario = null;

    if (leadData?.attemptsLeft >= 3 && !!hours && !!minutes) {
      return (
        <div className="scenario-1-inner-text">
          {/* Give the Screening Test to be considered for 30% Scholarship within: <span className="highlight-red">{hours} hrs {minutes} mins</span> */}
          Give the Screening Test to be evaluated for the Scholarship
          <br />
          Or warm-up with the practice test!
        </div>
      );
    } else if (leadData?.applicationStatus === 1 && leadData?.attemptsLeft) {
      return (
        <div className="scenario-1-inner-text">
          Give the screening test to move towards enrollment.
        </div>
      );
    } else if (leadData?.applicationStatus === 2) {
      return (
        <div className="scenario-1-inner-text">
          Your results are yet to be declared.
          <br />
          <span className="scenario-1-inner-text">
            Connect with your counselor{" "}
            <a
              className="counselor-no-hl"
              href={
                leadData?.counselorNo ? `tel:+91${leadData?.counselorNo}` : "#"
              }
            >
              {leadData?.counselorName ?? "--"} ({leadData?.counselorNo ?? "--"}
              )
            </a>
          </span>
        </div>
      );
    } else if (leadData?.applicationStatus === 5) {
      return (
        <div className="scenario-1-inner-text">
         Complete the registration process. <br/>
         Connect with your counselor {leadData?.counselorName ?? "--"} (
          {leadData?.counselorNo ?? "--"})
        </div>
      );
    } else if (
      leadData?.applicationStatus === 10 &&
      leadData?.attemptsLeft <= 0
    ) {
      return (
        <div className="scenario-1-inner-text">
          Your attempts are over. <br/>
          Connect with your counselor {leadData?.counselorName ?? "--"} (
          {leadData?.counselorNo ?? "--"})
        </div>
      );
    }

    return "";
  };

  const handleFeePayment = () => {
    if (typeof window && leadData?.feePaymentLink)
      window.open(leadData?.feePaymentLink, "_blank");
  };

  return (
    <footer className="desk-footer-outer-container">
      <h3 className="outer-header-heading">
        Kraftshala Screening Test (60 min)
        <br />
        <span className="outer-sub-header">
          {leadData?.attemptsLeft ?? "--"}/3 attempts remaining
        </span>
      </h3>
      <div className="desk-inner-header-container">
        <div className="inner-header-text">
          <InnerHeaderText />
        </div>
        <div className="action-container">
          {leadData?.applicationStatus !== 5 && (
            <>
              <button
                className="begin-test-btn"
                disabled={
                  !(
                    leadData?.applicationStatus === 1 &&
                    leadData?.attemptsLeft > 0
                  )
                }
                onClick={() => handleDetailsForm("StartTest_sticky_desk")}
              >
                Start Test
              </button>
              <button
                className="about-test-link-footer"
                target="_blank"
                onClick={() => handleDetailsForm("PracticeTest_sticky_desk")}
              >
                {leadData?.applicationStatus === 5 ? "" : "Give Practice Test"}
              </button>
            </>
          )}
          {leadData?.applicationStatus === 5 && (
            <>
              <button className="pay-fees-btn" onClick={handleFeePayment}>
                Pay Fee
              </button>
            </>
          )}
        </div>
      </div>
    </footer>
  );
};

const MobileFooter = (props) => {
  const { hours, minutes, leadData, handleDetailsForm } = props;

  const handleFeePayment = () => {
    if (typeof window && leadData?.feePaymentLink)
      window.open(leadData?.feePaymentLink, "_blank");
  };

  return (
    <footer className="on-demand-test-mob-footer">
      <div className="mob-footer-header">
        {(leadData?.applicationStatus === 1 ||
          leadData?.applicationStatus === 10) && (
          <>
            Kraftshala Screening Test (60 mins)
            <br />
            <span className="mob-sub-header">
              {" "}
              {leadData?.attemptsLeft ?? "--"}/3 attempts remaining{" "}
            </span>
          </>
        )}
        {leadData?.applicationStatus === 2 && (
          <>
            Your results are yet to be declared<br />
            <span> Connect with <a
              className="counselor-no-hl"
              href={
                leadData?.counselorNo
                  ? `tel:+91${leadData?.counselorNo}`
                  : "#"
              }
            >
              {leadData?.counselorName ?? "--"} (
              {leadData?.counselorNo ?? "--"})
            </a>
            </span>
          </>
        )}
        {leadData?.applicationStatus === 5 && (
          <>Complete the registration process <br className="pc-hide" />
          Connect with {leadData?.counselorName ?? "--"}  {leadData?.counselorNo ?? "--"}
          </>
        )}
      </div>
      {leadData?.applicationStatus !== 5 && (
        <button
          className="begin-test-btn"
          disabled={
            !(leadData?.applicationStatus === 1 && leadData?.attemptsLeft > 0)
          }
          onClick={() => handleDetailsForm("StartTest_sticky_mob")}
        >
          Start Test
        </button>
      )}
      {leadData?.applicationStatus === 5 && (
        <button className="begin-test-btn" onClick={handleFeePayment}>
          Pay the Fee
        </button>
      )}
      {leadData?.applicationStatus !== 5 && (
        <div className="attempts-remaining-text">
          <div
            className="about-bottom-test"
            target="_blank"
            onClick={() =>
              leadData?.attemptsLeft !== 0 &&
              handleDetailsForm("PracticeTest_sticky_mob")
            }
          >
            {leadData?.attemptsLeft !== 0
              ? "Give Practice Test"
              : `Connect with ${leadData?.counselorName} (${leadData?.counselorNo})`}
          </div>
        </div>
      )}
    </footer>
  );
};

export default ScreeningTestPageFooter;