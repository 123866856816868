import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { navigate } from 'gatsby';

export default function AlertDialog(props) {

  const {
    isOpen,
    handleDialogClose,
    type
  } = props;

  const currentAlertData = alertData[type ?? "incorrect_url"];

  const handleRedirect = () => {
    navigate(
      "/marketing-launchpad",
      // {
      //   state: {isIncorrectTestURL: true}
      // }
    );
  };
  
  return (
    <div>
      <Dialog
        open={isOpen}
        // onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {currentAlertData?.title ?? "Oops! something went wrong 🫤"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {currentAlertData?.content ?? "Please try again later"} 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={handleRedirect}>
            {currentAlertData?.actionBtnText ?? "close"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const alertData = {
  "incorrect_url": {
    title: "Oops! Looks like you entered the incorrect link 🫤",
    content: "To give your test / apply for The Marketing Launchpad, click ok.",
    actionBtnText: "Ok"
  },
  "expired": {
    title: "Welcome back! This assessment URL has expired 🫤",
    content: "To apply to the Marketing Launchpad, click 'Apply' and submit the form on the next page",
    actionBtnText: "APPLY"
  }
};
