import React from "react";

import popupBtnImg from "../../ContactUsPopup/Assests/popup-btn-img.png";

import "./styles.scss";

const SpeakToCounsellorDialog = (props) => {

  const {
    leadData
  } = props;

  const handleCounselorDialog = () => {
    const contactUsDialog = document.querySelector("#speak-to-counselor-dialog-box");
    
    if(contactUsDialog) {
      contactUsDialog.showModal();
    }
  };

  const handleDialogClose = () => {
    const contactUsDialog = document.querySelector("#speak-to-counselor-dialog-box");
  
    if(contactUsDialog) {
      contactUsDialog.close();
    }
  };
  
  return (
    <>
      <button className="counselor-dialog-popup-btn" onClick={handleCounselorDialog} >
        <img
          src={popupBtnImg}
          alt="counselor-dialog-popup-btn"
          loading="lazy"
        />
      </button>

      <dialog id="speak-to-counselor-dialog-box">
        <div className="outer-box">
          <div className="dialog-header">
            <h2 className="header-text">Got a Question?</h2>
          </div>
          <button className="dialog-toggle-btn" onClick={handleDialogClose}> ✕ </button>
          <div className="inner-box">
            <div className="phone-info-box">
              <p className="text">
                For any query,<br/> you can call {leadData?.counselorName ?? "us"} at:
              </p>
              <p className="phone-no-text">
                <a href={leadData?.counselorNo ? `tel:+91${leadData?.counselorNo}`: "tel:+917827135490"}>
                  {leadData?.counselorNo ? `+91 ${leadData?.counselorNo}`: "+91 7827135490"}
                </a>
              </p>
            </div>
            <hr className="hr-style"/>
            <div className="email-info-box">
              <p className="text">
                Or email us at:
              </p>
              <p className="email-id-text">
                <a href="mailto:contact@kraftshala.com">
                  {"contact@kraftshala.com"}
                </a>
              </p>
            </div>
          </div>
        </div>
      </dialog>
  
    </>
  );
};

export default SpeakToCounsellorDialog;