import React, { useMemo, useState } from "react";
import { styled } from "@mui/material/styles";
import Check from "@mui/icons-material/Check";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import useMediaQuery from "@mui/material/useMediaQuery";
import HourglassImage from "../../../images/common/hour-glass.svg";
import "./styles.scss";
import yUndeline from "../../../../static/assets/new_underline.png";
import testIcon from "../../../images/common/testIcon.png";
import dots1 from "../../../images/onDemandTest/dotsBg.png";
import dotsPc from "../../../images/onDemandTest/bookImg.png";
import { Step, StepContent, StepLabel, Stepper } from "@mui/material";

const HeroSection = (props) => {
  const {
    hours,
    minutes,
    leadData,
    handleAlertOpen,
    handleDetailsForm,
  } = props;

  return (
    <>
      {leadData?.formType !== "EMERGING" &&
        leadData?.attemptsLeft >= 3 &&
        !!hours &&
        !!minutes && (
        <div className="scholarship-marquee-container">
          <div className="marquee">
            <span className="marquee-text">
              <b className="hl-bold">
                {hours} hours {minutes} mins{" "}
              </b>{" "}
                left to be considered for the Scholarship worth{" "}
              <b className="hl-bold">₹30,000</b>
            </span>
            <span className="marquee-text">
              <b className="hl-bold">
                {hours} hours {minutes} mins{" "}
              </b>{" "}
                left to be considered for the Scholarship worth{" "}
              <b className="hl-bold">₹30,000</b>
            </span>
          </div>
        </div>
      )}

      <img src={dots1} className="on-demand-bg-dots pc-hide" />
      <img src={dotsPc} className="on-demand-bg-dots-pc mobile-hide" />
      {/* <img src={dots2} className="on-demand-bg-dots-2" /> */}
      <section className="on-deman-test-hero-main">
        <CustomizedSteppers
          hours={hours}
          minutes={minutes}
          leadData={leadData}
          handleAlertOpen={handleAlertOpen}
          handleDetailsForm={handleDetailsForm}
        />
      </section>
    </>
  );
};

const CustomizedSteppers = (props) => {
  const {
    hours,
    minutes,
    leadData,
    handleAlertOpen,
    handleDetailsForm,
  } = props;

  const matches = useMediaQuery(`(max-width: 900px)`);
  const [detailsFormState, setDetailsFormState] = useState({
    isOpen: false,
  });

  const activeStep = useMemo(() => {
    if (leadData?.applicationStatus === 5) return 3;

    return 1;
  }, [leadData]);

  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 16,
      left: "calc(-50% + 24px)",
      right: "calc(50% + 24px)",
      backgroundColor: "#D9D9D9",
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#3DBD69",
        width: "50%",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#3DBD69",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor:
        theme.palette.mode === "dark" ? theme.palette.grey[800] : "#D9D9D9",
      borderTopWidth: 3,
      borderLeftWidth: 2,
      borderRadius: 1,
    },
  }));

  function QontoStepIcon(props) {
    const matches = useMediaQuery(`(max-width: 900px)`);

    const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
      color:
        theme.palette.mode === "dark" ? theme.palette.grey[700] : "#D9D9D9",
      display: "flex",
      height: matches ? 24 : 36,
      width: matches ? 24 : 36,
      justifyContent: "center",
      alignItems: "center",
      ...(ownerState.active && {
        color:
          leadData?.applicationStatus === 1 ||
          leadData?.applicationStatus === 5 ||
          leadData?.applicationStatus === 2
            ? "#E23F5B"
            : "#D9D9D9",
        width: matches ? 24 : 36,
        justifyContent: "center",
        border: "1px solid",
        borderRadius: "50%",
      }),
      "& .QontoStepIcon-completedIcon": {
        backgroundColor: "#3DBD69",
        color: "#fff",
        zIndex: 1,
        fontSize: matches ? 24 : 36,
        padding: matches ? "4px" : "6px",
        borderRadius: "50%",
      },
      "& .QontoStepIcon-circle": {
        width: matches ? 8 : 14,
        height: matches ? 8 : 14,
        borderRadius: "50%",
        backgroundColor: "currentColor",
        ...(ownerState.active && {
          animation:
            leadData?.applicationStatus === 1 ||
            leadData?.applicationStatus === 5 ||
            leadData?.applicationStatus === 2
              ? "blink-animation 1s steps(2, start) infinite"
              : "none",
        }),
      },
    }));

    const { active, completed, className } = props;

    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (
          <Check className="QontoStepIcon-completedIcon" />
        ) : (
          <div className="QontoStepIcon-circle" />
        )}
      </QontoStepIconRoot>
    );
  }

  const HelperText = () => {
    switch (leadData?.applicationStatus) {
    case 1:
      return (
        <>
          <div className="helper-text">
              Welcome to the Admission Process
            <br />
              of <b>Marketing Launchpad program!</b> <br />
          </div>
          <div className="helper-text">
              India’s #1 Placement-linked
            <br /> Digital Marketing Course
          </div>
        </>
      );

    case 2:
      return (
        <>
          <div className="helper-text">
              Welcome to the Admission Process
            <br />
              of <b>Marketing Launchpad program!</b> <br />
          </div>
          <div className="helper-text">
              India’s #1 Placement-linked
            <br /> Digital Marketing Course
          </div>
        </>
      );

    case 5:
      return (
        <>
          <div className="helper-text">
              Welcome to the Admission Process
            <br />
              of <b>Marketing Launchpad program!</b> <br />
          </div>
          <div className="helper-text">
              India’s #1 Placement-linked
            <br /> Digital Marketing Course
          </div>
        </>
      );

    case 10:
      return (
        <>
          <div className="helper-text">
              Welcome to the Admission Process
            <br />
              of <b>Marketing Launchpad program!</b> <br />
          </div>
          <div className="helper-text">
              India’s #1 Placement-linked
            <br /> Digital Marketing Course
          </div>
        </>
      );

    default:
      return "";
    }
  };

  const handleFeePayment = () => {
    if (typeof window && leadData?.feePaymentLink)
      window.open(leadData?.feePaymentLink, "_blank");
  };

  const StepperContentText = () => {
    /* When attempts are left & lead has not yet cleared the Test */
    if (leadData?.applicationStatus === 1 && leadData?.attemptsLeft > 0) {
      return <div></div>;
    }

    /*When no attempts are left & lead has not cleared the test */
    if (leadData?.applicationStatus === 10 && leadData?.attemptsLeft <= 0) {
      return <div className="step-desc"></div>;
    }

    return "";
  };

  return (
    <div className="on-demand-stepper-main">
      <div
        className="on-demand-header-main"
        style={{
          marginTop:
            leadData?.attemptsLeft >= 3 && !!hours && !!minutes ? "1em" : "3em",
        }}
      >
        <h1 className="lead-name">
          Hey {leadData?.leadName.split(" ")[0]}!<br />
          <img src={yUndeline} className="test-page-underline" />
        </h1>
        <HelperText />
      </div>
      <div className="stepper-section">
        {leadData?.applicationStatus === 1 && (
          <div className="next-step-stepper-h">
            {" "}
            Next Step?{" "}
            <span className="yellow-next">Give the free Screening Test</span>
          </div>
        )}
        {leadData?.applicationStatus === 2 && (
          <div className="next-step-stepper-h">
            {" "}
            Next Step?{" "}
            <span className="yellow-next">
              Wait for the results for 2-3 days!
            </span>
          </div>
        )}
        {leadData?.applicationStatus === 5 && (
          <div className="next-step-stepper-h">
            {" "}
            Next Step?{" "}
            <span className="yellow-next">Congratulations! You’re in!</span>
          </div>
        )}
        {leadData?.applicationStatus === 10 && (
          <div className="next-step-stepper-h">
            {" "}
            Next Step?{" "}
            <span className="yellow-next">
              Sorry! Your 3 attempts are over :(
            </span>
          </div>
        )}
        <Stepper
          alternativeLabel={!matches}
          activeStep={activeStep}
          connector={<QontoConnector />}
          orientation={matches ? "vertical" : "horizontal"}
        >
          <Step>
            <StepLabel StepIconComponent={QontoStepIcon}>
              <div className="step-label-main">Application Submitted</div>
            </StepLabel>
            {leadData?.attemptsLeft >= 3 && !!hours && !!minutes && (
              <div className="sch-info-section">
                <img
                  className="hourglass-img"
                  src={HourglassImage}
                  alt="hourglass image"
                  loading="lazy"
                />

                <div className="sch-timer-text">
                  Scholarship eligibility <br />
                  expires in {hours} hours {minutes} mins
                </div>
              </div>
            )}
          </Step>
          <Step>
            <StepLabel StepIconComponent={QontoStepIcon}>
              <div className="step-label-main">Free Screening Test</div>
            </StepLabel>
            {!matches && (
              <StepContent className="screening-test-step-content desk-step-content">
                <button
                  className="start-test-btn"
                  disabled={
                    !(
                      leadData?.applicationStatus !== 2 &&
                      leadData?.attemptsLeft > 0
                    )
                  }
                  onClick={() => handleDetailsForm("StartTest_inline_desk")}
                >
                  {leadData?.applicationStatus !== 5
                    ? "Start Test"
                    : "Pay the Fee"}
                </button>
                <StepperContentText />
              </StepContent>
            )}
            {matches && (
              <StepContent className="screening-test-step-content mob-step-content">
                <button
                  className="start-test-btn"
                  disabled={
                    !(
                      leadData?.applicationStatus !== 2 &&
                      leadData?.attemptsLeft > 0
                    )
                  }
                  onClick={() => handleDetailsForm("StartTest_inline_mob")}
                >
                  Start Test
                </button>
                <StepperContentText />
              </StepContent>
            )}
          </Step>
          <Step>
            <StepLabel StepIconComponent={QontoStepIcon}>
              <div className="step-label-main">
                Career Chat
                <span>With Admissions Team</span>
              </div>
            </StepLabel>
            <div></div>
          </Step>
          <Step>
            <StepLabel StepIconComponent={QontoStepIcon}>
              <div
                className="step-label-main"
                style={{ marginBottom: "-0.5em" }}
              >
                Start Learning
                <span>And get placed in 5 months</span>
              </div>
            </StepLabel>
            {!matches && (
              <StepContent className="screening-test-step-content desk-step-content final-step-content">
                <button className="start-test-btn" onClick={handleFeePayment}>
                  Pay Fee
                </button>
                <StepperContentText />
              </StepContent>
            )}
            {matches && (
              <StepContent className="screening-test-step-content mob-step-content mob-final-step-content">
                <button className="start-test-btn" onClick={handleFeePayment}>
                  Pay Fee
                </button>
                <StepperContentText />
              </StepContent>
            )}
          </Step>
        </Stepper>

        <div
          className="next-flex"
          style={{
            marginTop: leadData?.applicationStatus !== 10 ? "1em" : 0,
            transform:
              leadData?.applicationStatus !== 5
                ? "translateX(30.5em)"
                : "translateX(72.5em)",
          }}
        >
          <div className="next-sec-img">
            <img src={testIcon} alt="test" />
          </div>
          {leadData?.applicationStatus !== 5 ? (
            <div className="next-sec">
              <div className="learnApply">
                Learn & Apply <br />
                Marketing Concepts
              </div>
              <div className="next-time">
                60 mins | {leadData?.attemptsLeft} attempts 
              </div>
            </div>
          ) : (
            <div className="next-sec">
              <div className="learnApply">
                Move towards
                <br />
                enrollment & get
                <br />
                placed in 5 months
              </div>
              <div className="next-time">
                60 mins | {leadData?.attemptsLeft} attempts 
              </div>
            </div>
          )}
        </div>
        <div
          className="stepper-btm-text"
          style={{
            marginTop: leadData?.applicationStatus === 5 ? "1.3em" : "2.1em",
          }}
        >
          We’ve also shared details on: <br />{" "}
          <a
            href={`mailto:${leadData?.leadEmail}`}
            className="stepper-bottom-link"
          >
            {leadData?.leadEmail}
          </a>{" "}
          | {leadData?.leadPhoneNo}
        </div>
      </div>
      <div className="next-step-sec">
        <div className="nxt-head">Next Step?</div>
        {leadData?.applicationStatus === 1 && (
          <div className="free-test">Give the free Screening Test</div>
        )}
        {leadData?.applicationStatus === 2 && (
          <div className="free-test">Wait for the results for 2-3 days!</div>
        )}
        {leadData?.applicationStatus === 5 && (
          <div className="free-test">Congratulations! You’re in!</div>
        )}
        {leadData?.applicationStatus === 10 && leadData?.attemptsLeft === 0 && (
          <div className="free-test">Sorry! 3 attempts are over {":("}</div>
        )}

        {leadData?.applicationStatus !== 5 ? (
          <button
            className="start-test-btn"
            disabled={
              !(leadData?.applicationStatus !== 2 && leadData?.attemptsLeft > 0)
            }
            onClick={() => handleDetailsForm("StartTest_inline_mob")}
          >
            Start Test
          </button>
        ) : (
          <button className="start-test-btn" onClick={handleFeePayment}>
            Pay the Fee
          </button>
        )}
        <div className="next-flex">
          <div className="next-sec-img">
            <img src={testIcon} alt="test" />
          </div>
          <div className="next-sec">
            {leadData?.applicationStatus !== 5 ? (
              <div className="learnApply">
                Learn & Apply <br className="pc-hide" />
                Marketing Concepts
              </div>
            ) : (
              <div className="learnApply">
                Move towards
                <br className="pc-hide" />
                enrollment & get
                <br className="pc-hide" />
                placed in 5 months
              </div>
            )}
            <div className="next-time">
              60 mins | {leadData?.attemptsLeft} attempts 
            </div>
          </div>
        </div>
        <div className="stepper-btm-text">
          We’ve also shared details on: <br />{" "}
          <a
            href={`mailto:${leadData?.leadEmail}`}
            className="stepper-bottom-link"
          >
            {leadData?.leadEmail}
          </a>{" "}
          <br className="pc-hide" />
          {leadData?.leadPhoneNo}
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
