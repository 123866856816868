import React, { useEffect, useRef, useState } from "react";
import HeroSection from "./HeroSection";

import "./styles.scss";
import AboutScreeningTest from "./AboutTest";
import ScreeningTestPageFooter from "./Footer";
import { CONVEX_API_URL } from "../../../config/config";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import AlertDialog from "./AlertDialog";
import LeadDetailsForm from "./LeadDetailsForm";
import { useLocation } from "@reach/router";
import ChangeEmailDialog from "./ChangeEmailDialog";
import rightDotImg from "../../images/onDemandTest/ondemandPcBg.png";

const OnDemandTest = (props) => {
  ///marketing-launchpad/screening-test/14bd27a3-0e03-460a-add4-3f8b4b551b78/cd261436-d3e4-4706-afe8-3438139bcb57
  const testID = props?.params?.test_id ?? null;
  const leadID = props?.params?.lead_id ?? null;

  const location = useLocation();

  const [isLoading, setLoading] = useState(false);
  const [leadData, setLeadData] = useState(null);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [alertState, setAlert] = useState({
    severity: "info",
    message: "",
    isOpen: false,
  });
  const [dialogState, setDialogState] = useState({
    isOpen: false,
    type: "incorrect_url",
  });
  const [detailsFormState, setDetailsFormState] = useState({
    isOpen: false,
  });
  const [changeEmailDialog, setChangeEmailDialog] = useState({
    isOpen: false,
  });
  const timerRef = useRef(null);
  const leadRefreshRef = useRef(null);

  useEffect(() => {
    let locState = location?.state;

    // if(typeof window) {
    //   const isPopupShown = window.sessionStorage.getItem("isPopupShown");
    //   if(isPopupShown === "yes")
    //     return;
    // }
    if (locState?.isRedirect) {
      handleAlertOpen("OTP Verified Successfully!", "success");
    }

    if (locState?.isNewEmail) {
      /* Open change Email Id Dialog box*/
      handleChangeEmailDialog();
    }

    // if(typeof window) {
    //   window.sessionStorage.setItem("isPopupShown", "yes");
    // }

    // return () => navigate(location.pathname, {state: null});
  }, []);

  useEffect(() => {
    if (testID && leadID) {
      getLeadData();
    }

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
        timerRef.current = null;
      }

      if (leadRefreshRef.current) {
        clearInterval(leadRefreshRef.current);
        leadRefreshRef.current = null;
      }
    };
  }, []);

  /* Fetch the current Lead Data */
  const getLeadData = async () => {
    try {
      setLoading(true);
      const url = `${CONVEX_API_URL}/lead/${testID}/${leadID}/get-on-demand/data`;
      const result = await fetch(url);
      const data = await result.json();

      if (!result.ok && !data?.id) {
        throw data;
      }

      let attemptsLeft = 0;
      let applicationStatus = 1;
      let testLink = null;
      let feePaymentLink = null;
      let counselorName = "Kay";
      let counselorEmail = "contact@kraftshala.com";
      let counselorNo = "7827135490";
      let showDetailsForm = true;
      let formType = "";

      if (data?.id) {
        if (data.deletedAt) {
          let errorObj = { message: "test link expired" };

          throw errorObj;
        }

        applicationStatus = data.AssessmentParticipantModel?.[0]?.status;

        switch (data.AssessmentParticipantModel.length) {
        case 1:
          attemptsLeft = applicationStatus === 1 ? 3 : 2;
          break;
        case 2:
          attemptsLeft = applicationStatus === 1 ? 2 : 1;
          break;
        case 3:
          attemptsLeft = applicationStatus === 1 ? 1 : 0;
          break;
        default:
          break;
        }

        testLink = data.AssessmentParticipantModel?.[0]?.assessment_link;

        if (
          data.CouncelorModel?.email &&
          data.CouncelorModel?.name &&
          data.CouncelorModel?.phone &&
          data.CouncelorModel?.email !== "varun@kraftshala.com"
        ) {
          counselorName = data.CouncelorModel?.name ?? counselorName;
          counselorEmail = data.CouncelorModel?.email ?? counselorEmail;
          counselorNo =
            data.CouncelorModel?.phone?.replace("+91-", "") ?? counselorNo;
        }

        if (applicationStatus === 5)
          feePaymentLink =
            data.AssessmentParticipantModel?.[0]?.creation_response;

        showDetailsForm = !data?.LeadDetailsModel?.id;
        formType = data?.form_page_type;

        let newLeadData = {
          leadID,
          leadName: data?.name,
          leadEmail: data?.email,
          leadPhoneNo: data?.phone,
          applicationStatus,
          attemptsLeft,
          // applicationStatus: 1,
          // attemptsLeft: 1,
          testLink,
          feePaymentLink,
          counselorName,
          counselorEmail,
          counselorNo,
          showDetailsForm,
          formType,
        };

        setLeadData(newLeadData);
      }

      if (attemptsLeft >= 3) initiateScholarshipTimer(data?.createdAt);

      if (applicationStatus !== 10) {
        // referesh the lead data every 60s
        leadRefreshRef.current = setInterval(
          () => refereshLeadData(),
          1000 * 60
        );
      }
    } catch (e) {
      console.error(e);

      if (e?.message === "Invalid Email ID.") {
        handleDialogOpen("incorrect_url");
      } else if (e?.message === "test link expired") {
        handleDialogOpen("expired");
      } else {
        // "Couldn't fetch admission details! Please try again later.",
        handleAlertOpen("!Oops, something went wrong. Please try again later");
      }
    } finally {
      setLoading(false);
    }
  };

  const initiateScholarshipTimer = (createdAt) => {
    const deadlineTime = new Date(createdAt).getTime() + 72 * 60 * 60 * 1000; // Scholarship deadline will be 72hrs from the lead creation time.

    if (timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }

    /* Initially call the function once */
    updateTime(deadlineTime);
    /* Update Every 60 seconds */
    timerRef.current = setInterval(() => updateTime(deadlineTime), 1000 * 60);
  };

  const updateTime = (deadline) => {
    const time = deadline - Date.now();

    if (time < 0) {
      setHours(0);
      setMinutes(0);
      clearInterval(timerRef.current);
      timerRef.current = null;

      return;
    }

    setHours(Math.floor(time / (1000 * 60 * 60)));
    setMinutes(Math.floor((time / 1000 / 60) % 60));
    //setSeconds(Math.floor((time / 1000) % 60));
  };

  /* Function to referesh lead data */
  const refereshLeadData = async () => {
    try {
      const url = `${CONVEX_API_URL}/lead/${testID}/${leadID}/get-on-demand/data`;
      const result = await fetch(url);
      const data = await result.json();

      if (!result.ok && !data?.id) {
        throw data;
      }

      let attemptsLeft = 0;
      let applicationStatus = 1;
      let testLink = null;
      let feePaymentLink = null;
      let counselorName = "Kay";
      let counselorEmail = "contact@kraftshala.com";
      let counselorNo = "7827135490";
      let showDetailsForm = true;

      if (data?.id) {
        applicationStatus = data.AssessmentParticipantModel?.[0]?.status;

        switch (data.AssessmentParticipantModel.length) {
        case 1:
          attemptsLeft = applicationStatus === 1 ? 3 : 2;
          break;
        case 2:
          attemptsLeft = applicationStatus === 1 ? 2 : 1;
          break;
        case 3:
          attemptsLeft = applicationStatus === 1 ? 1 : 0;
          break;
        default:
          break;
        }

        testLink = data.AssessmentParticipantModel?.[0]?.assessment_link;

        if (
          data.CouncelorModel?.email &&
          data.CouncelorModel?.name &&
          data.CouncelorModel?.phone &&
          data.CouncelorModel?.email !== "varun@kraftshala.com"
        ) {
          counselorName = data.CouncelorModel?.name ?? counselorName;
          counselorEmail = data.CouncelorModel?.email ?? counselorEmail;
          counselorNo =
            data.CouncelorModel?.phone?.replace("+91-", "") ?? counselorNo;
        }

        if (applicationStatus === 5)
          feePaymentLink =
            data.AssessmentParticipantModel?.[0]?.creation_response;

        showDetailsForm = !data?.LeadDetailsModel?.id;

        let newLeadData = {
          leadID,
          leadName: data?.name,
          leadEmail: data?.email,
          leadPhoneNo: data?.phone,
          applicationStatus,
          attemptsLeft,
          // applicationStatus: 2,
          // attemptsLeft: 1,
          testLink,
          feePaymentLink,
          counselorName,
          counselorEmail,
          counselorNo,
          showDetailsForm,
          formType,
        };

        setLeadData(newLeadData);

        if (applicationStatus === 10) {
          clearInterval(leadRefreshRef.current);
          leadRefreshRef.current = null;
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const callCta = async (type) => {
    try {
      await fetch(`${CONVEX_API_URL}/assessment/addCtaCount`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ type, id: leadID }), // Convert data to JSON string
      });
    } catch (e) {
      console.error(e);
    }
  };

  const handleDetailsForm = async (type) => {
    
    // Check if type is "sticky-practice-test" or "inline-practice-test"
    if (type.toLowerCase().includes('practice')) {
      // Open practice form
      setTimeout(() => {
        window.open(
          `https://kraftshala.formaloo.me/n5z1fw?email=${leadData?.leadEmail}&number=${leadData?.leadPhoneNo}`,
          "_blank"
        );
      }, 100);
  
      handleAlertClose();
      callCta(type);

      return;
    }
    
    if (!leadData?.showDetailsForm) {
      handleStartTest();
      handleAlertClose();
      callCta(type);
  
      return;
    }
  
    // Show details form if required
    handleAlertClose();
    setDetailsFormState({
      ...detailsFormState,
      isOpen: true,
    });
  
    callCta(type);
  };
  
  const closeDetailsForm = () => {
    setDetailsFormState({
      ...detailsFormState,
      isOpen: false,
    });
  };

  const handleStartTest = () => {
    if (typeof window && window.gtag) {
      window.gtag("config", "AW-819495038");

      window.gtag("event", "conversion", {
        send_to: "AW-819495038/RFO7CNKw3-gYEP6A4oYD",
      });
    }

    if (typeof window && leadData?.testLink) {
      setTimeout(() => {
        window.open(leadData?.testLink, "_blank");
      }, 100);
    } // Short delay to potentially bypass strict popup blockers
  };

  const handleAlertOpen = (message, severity = "error") => {
    setAlert({
      severity,
      message,
      isOpen: true,
    });
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlert({
      ...alertState,
      message: "",
      isOpen: false,
    });
  };

  const handleDialogOpen = (type) => {
    setDialogState({
      ...dialogState,
      type,
      isOpen: !dialogState.isOpen,
    });
  };

  const handleDialogClose = () => {
    setDialogState({
      ...dialogState,
      isOpen: !dialogState.isOpen,
    });
  };

  const handleChangeEmailDialog = () => {
    // const changeEmailDialog = document.querySelector("#change-email-dialog-box");

    // if(changeEmailDialog) {
    //   changeEmailDialog.showModal();
    // }
    setChangeEmailDialog({
      isOpen: !changeEmailDialog.isOpen,
    });
  };

  if (isLoading) {
    return (
      <div className="loader-container-main">
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <img src={rightDotImg} className="rightBgImg" loading="lazy" />
      <div className="on-demand-test-main">
        <HeroSection
          leadData={leadData}
          hours={hours}
          minutes={minutes}
          handleAlertOpen={handleAlertOpen}
          handleDetailsForm={handleDetailsForm}
        />
        <AboutScreeningTest
          leadData={leadData}
          leadPhoneNo={leadData?.leadPhoneNo}
          formType={leadData?.formType}
          leadEmail={leadData?.leadEmail ?? ""}
          handleDetailsForm={handleDetailsForm}
        />
        <ScreeningTestPageFooter
          leadData={leadData}
          hours={hours}
          minutes={minutes}
          handleDetailsForm={handleDetailsForm}
          callCta={callCta}
        />
      </div>

      <LeadDetailsForm
        isOpen={detailsFormState.isOpen}
        leadData={leadData}
        handleStartTest={handleStartTest}
        closeDetailsForm={closeDetailsForm}
        handleAlertOpen={handleAlertOpen}
        refereshLeadData={refereshLeadData}
        getLeadData={getLeadData}
        handleDetailsForm={handleDetailsForm}
        callCta={callCta}
      />

      <ChangeEmailDialog
        isOpen={changeEmailDialog.isOpen}
        leadData={leadData}
        handleChangeEmailDialog={handleChangeEmailDialog}
      />

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={alertState.isOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert
          onClose={handleAlertClose}
          severity={alertState?.severity}
          sx={{ width: "100%" }}
        >
          {alertState.message}
        </Alert>
      </Snackbar>

      <AlertDialog
        isOpen={dialogState.isOpen}
        type={dialogState.type}
        handleDialogClose={handleDialogClose}
      />
    </>
  );
};

export default OnDemandTest;
